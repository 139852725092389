import { createSlice } from "@reduxjs/toolkit";

const cartInitialState = {
  cart: {},
  cartLoading: false,
  cartView: {},
  payment: {
    subTotal: 0,
    discount: 0,
    deliveryCharges: 0,
    tax: 0,
    total: 0,
  },
  isCart: false,
  isCartView: false,
};

const CartSlice = createSlice({
  name: "cart",
  initialState: cartInitialState,
  reducers: {
    setCartData(state, action) {
      if (!action.payload.cart) {
        return;
      }
      state.cart = action.payload.cart;
    },
    setCartView(state, action) {
      state.cartView = action.payload.cartView;
    },
    pushProduct(state, action) {
      const { productId, quantity } = action.payload;
      console.log(action.payload);
      if (state.cart[productId]) {
        state.cart[productId].quantity += quantity;
        if (state.cart[productId] <= 0) {
          delete state.cart[productId];
        }
      } else {
        state.cart[productId] = {
          quantity: quantity,
        };
      }
    },
    cartLoading(state, action) {
      state.cartLoading = action.payload;
    },
    cartFetched(state, action) {
      state.isCart = action.payload;
    },
    cartViewFetched(state, action) {
      state.isCartView = action.payload;
    },
    setPayment(state, action) {
      console.log(action.payload.payment);
      if (!action.payload.payment) {
        return;
      }
      state.payment = action.payload.payment;
    },
    clearCartData(state, action) {
      state.cart = {};
      state.cartView = {};
      state.payment = {
        subTotal: 0,
        discount: 0,
        deliveryCharges: 0,
        tax: 0,
        total: 0,
      };
      state.isCart = false;
      state.isCartView = false;
    },
  },
});

export const CartAction = CartSlice.actions;
export default CartSlice.reducer;

import styles from "./_cart.module.scss";
import Card from "../../Utils/Card/Card";
import CartProduct from "./CartProduct/CartProduct";
import Button from "../../Utils/button/Button";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { cartTrunk } from "../../../Store/cart-action";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { dotWave, hatch } from "ldrs";

dotWave.register();
hatch.register();

const Cart = () => {
  const cartState = useSelector((state) => state.CartSlice);
  const dispatch = useDispatch();
  const history = useHistory();
  const authState = useSelector((state) => state.Auth);
  const [isHovered, setIsHovered] = useState(false);

  const proceedToCheckoutHandler = () => {
    if (authState.isLoggedIn && authState.token) {
      if (cartState.payment.total <= 0) {
        history.push("/products");
      } else {
        history.push("/address");
      }
    } else {
      history.push("/login");
    }
  };

  function scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }

  useEffect(() => {
    scrollToTop();
  }, []);

  useEffect(() => {
    if (authState.isLoggedIn && authState.token) {
      const token = authState.token;
      dispatch(cartTrunk(token, 0, "getCart"));
      dispatch(cartTrunk(token, 1, "cartView"));
    } else {
      history.push("/login");
    }
  }, [dispatch, authState.token, authState.isLoggedIn, history]);

  return (
    <div className={styles.cart}>
      <h1 className={styles.h1}>Shopping Cart</h1>
      <div className={styles.cart_container}>
        <div className={styles.cart_container_1}>
          {cartState.isCart && cartState.isCartView ? (
            Object.entries(cartState.cart).map(([productId, addedProduct]) => (
              <CartProduct
                key={productId}
                productId={productId}
                addedProduct={addedProduct}
                prod={cartState.cartView[productId]}
              />
            ))
          ) : (
            <div className={styles.cartLoading}>
              <l-hatch
                size="65"
                stroke="5"
                speed="3.5"
                color="#e14d2a"
              ></l-hatch>
            </div>
          )}
        </div>
        <div className={styles.content}>
          <Card className={styles.cart_container_2}>
            <h2 className={styles.h2}>Price Details</h2>
            <ul className={styles.list}>
              <li className={styles.list_item}>
                <span className={styles.list_item_key}>SubTotal</span>
                <span className={styles.list_item_value}>
                  ₹{cartState.payment.subtotal}
                </span>
              </li>
              <li className={styles.list_item}>
                <span className={styles.list_item_key}>Discount</span>
                <span className={styles.list_item_value}>
                  {`- ₹${cartState.payment.discount}`}
                </span>
              </li>
              <li className={styles.list_item}>
                <span className={styles.list_item_key}>Delivery Charges</span>
                <span className={styles.list_item_value}>
                  {cartState.payment.deliveryCharge !== 0
                    ? `₹${cartState.payment.deliveryCharge}`
                    : "Free"}
                </span>
              </li>
              <li className={`${styles.list_item} ${styles.list_item_total}`}>
                <span
                  className={`${styles.list_item_key} ${styles.list_item_total_key}`}
                >
                  Total Amount
                </span>
                <span
                  className={`${styles.list_item_value} ${styles.list_item_total_value}`}
                >
                  ₹{cartState.payment.total}
                </span>
              </li>
            </ul>
          </Card>
          <div className={`${styles.checkout} ${styles.checkout_desktop}`}>
            <div className={styles.checkout_price}>
              <span className={styles.checkout_price_subtotal}>
                ₹{cartState.payment.subtotal}
              </span>
              <span className={styles.checkout_price_total}>
                {" "}
                ₹{cartState.payment.total}
              </span>
            </div>
            {!cartState.cartLoading ? (
              <Button
                className={styles.btn}
                onClick={() => {
                  proceedToCheckoutHandler();
                  setIsHovered(true);
                }}
              >
                {cartState.payment.total <= 0 && "Shop Now"}
                {!cartState.payment.total <= 0 && " Proceed To Checkout"}
              </Button>
            ) : (
              <Button
                className={styles.btn}
                onMouseEnter={() => {
                  setIsHovered(true);
                }}
                onMouseLeave={() => setIsHovered(false)}
              >
                <l-dot-wave
                  size="30"
                  speed="2.5"
                  color={isHovered ? "#e14d2a" : "white"}
                ></l-dot-wave>
              </Button>
            )}
          </div>
        </div>
      </div>
      <div className={`${styles.checkout} ${styles.checkout_mobile}`}>
        <div className={styles.checkout_price}>
          <span className={styles.checkout_price_subtotal}>
            {" "}
            ₹{cartState.payment.subtotal}
          </span>
          <span className={styles.checkout_price_total}>
            {" "}
            ₹{cartState.payment.total}
          </span>
        </div>
        {!cartState.cartLoading ? (
          <Button
            className={styles.btn}
            onClick={() => {
              proceedToCheckoutHandler();
              setIsHovered(true);
            }}
          >
            {cartState.payment.total <= 0 && "Shop Now"}
            {!cartState.payment.total <= 0 && " Proceed To Checkout"}
          </Button>
        ) : (
          <Button
            className={styles.btn}
            onMouseEnter={() => {
              setIsHovered(true);
            }}
            onMouseLeave={() => setIsHovered(false)}
          >
            <l-dot-wave
              size="40"
              speed="2.5"
              color={isHovered ? "#e14d2a" : "white"}
            ></l-dot-wave>
          </Button>
        )}
      </div>
    </div>
  );
};

export default Cart;

import { useEffect, useMemo, useRef, useState } from "react";
import Card from "../../Utils/Card/Card";
import AddressCard from "./AddressCard/AddressCard";
import styles from "./_address.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { AddressAction } from "../../../Store/address-slice";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { scrollToTop } from "../../../Helpers/goToTop";
import {
  toastifyError,
  toastifySuccess,
} from "../../../Helpers/notificationToastify";
import { trefoil } from "ldrs";

trefoil.register();

const credentials = [
  "First Name",
  "Last Name",
  "Mobile Number",
  "Address",
  "State",
  "City",
  "Postal Code",
  "Landmark",
];

const Address = () => {
  const indianStates = useMemo(
    () => [
      "Andhra Pradesh",
      "Arunachal Pradesh",
      "Assam",
      "Bihar",
      "Chhattisgarh",
      "Goa",
      "Gujarat",
      "Haryana",
      "Himachal Pradesh",
      "Jharkhand",
      "Karnataka",
      "Kerala",
      "Madhya Pradesh",
      "Maharashtra",
      "Manipur",
      "Meghalaya",
      "Mizoram",
      "Nagaland",
      "Odisha",
      "Punjab",
      "Rajasthan",
      "Sikkim",
      "Tamil Nadu",
      "Telangana",
      "Tripura",
      "Uttar Pradesh",
      "Uttarakhand",
      "West Bengal",
      "Andaman and Nicobar Islands",
      "Chandigarh",
      "Dadra and Nagar Haveli and Daman and Diu",
      "Delhi",
      "Jammu and Kashmir",
      "Ladakh",
      "Lakshadweep",
      "Puducherry",
    ],
    []
  );
  const firstName = useRef(null);
  const lastName = useRef(null);
  const mobileNumber = useRef(null);
  const address = useRef(null);
  const state = useRef(null);
  const city = useRef(null);
  const postalCode = useRef(null);
  const landmark = useRef(null);

  const [deliveryAddress, setDeliveryAddress] = useState({
    firstName: "",
    lastName: "",
    mobileNumber: "",
    state: "",
    city: "",
    address: "",
    landmark: "",
    postalCode: "",
  });

  useEffect(() => {
    scrollToTop();
  }, []);

  const formItem = credentials.map((item, i) => {
    if (item === "Address") {
      return (
        <div key={`${item}${i}`} className={styles.form_container_content}>
          <label htmlFor={`${item}${i}`}>
            {item}
            <span>*</span>
          </label>
          <textarea
            value={deliveryAddress.address ? deliveryAddress.address : ""}
            onChange={(e) => {
              setDeliveryAddress((pre) => {
                return { ...pre, address: e.target.value };
              });
            }}
            ref={address} // Associate the ref with the textarea
            rows="5"
            cols="33"
            placeholder={`Enter ${item}`}
            id={`${item}${i}`}
            required
          ></textarea>
        </div>
      );
    } else if (item === "State") {
      return (
        <div key={`${item}${i}`} className={styles.form_container_content}>
          <label htmlFor="indianStates">
            {item}
            <span>*</span>
          </label>
          <select
            ref={state}
            id="indianStates"
            value={deliveryAddress.state ? deliveryAddress.state : ""}
            onChange={(e) => {
              setDeliveryAddress((pre) => {
                return { ...pre, state: e.target.value };
              });
            }}
            required
          >
            <option value="">Select a state</option>
            {indianStates.map((stateName) => (
              <option key={stateName} value={stateName}>
                {stateName}
              </option>
            ))}
          </select>
        </div>
      );
    } else {
      return (
        <div key={`${item}${i}`} className={styles.form_container_content}>
          <label htmlFor={`${item}${i}`}>
            {item}
            <span>*</span>
          </label>
          <input
            ref={
              item === "First Name"
                ? firstName
                : item === "Last Name"
                ? lastName
                : item === "Mobile Number"
                ? mobileNumber
                : item === "City"
                ? city
                : item === "Postal Code"
                ? postalCode
                : landmark
            }
            value={
              item === "First Name"
                ? deliveryAddress.firstName || ""
                : item === "Last Name"
                ? deliveryAddress.lastName || ""
                : item === "Mobile Number"
                ? deliveryAddress.mobileNumber || ""
                : item === "City"
                ? deliveryAddress.city || ""
                : item === "Postal Code"
                ? deliveryAddress.postalCode || ""
                : deliveryAddress.landmark || ""
            }
            onChange={(e) => {
              setDeliveryAddress((pre) => ({
                ...pre,
                firstName:
                  item === "First Name" ? e.target.value : pre.firstName,
                lastName: item === "Last Name" ? e.target.value : pre.lastName,
                mobileNumber:
                  item === "Mobile Number" ? e.target.value : pre.mobileNumber,
                city: item === "City" ? e.target.value : pre.city,
                postalCode:
                  item === "Postal Code" ? e.target.value : pre.postalCode,
                landmark: item === "Landmark" ? e.target.value : pre.landmark,
              }));
            }}
            type="text"
            placeholder={`Enter ${item}`}
            id={`${item}${i}`}
            required
          ></input>
        </div>
      );
    }
  });

  const params = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const [isEdit, setIsEdit] = useState(false);
  const addressState = useSelector((state) => state.AddressSlice);
  const authState = useSelector((state) => state.Auth);
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    if (params.addressId && addressState.addressData.length > 0) {
      const addressId = params.addressId.slice(1, params.addressId.length);
      const givenAddress = addressState.addressData.filter(
        (address) => addressId.toString() === address._id.toString()
      );
      setDeliveryAddress({
        firstName: givenAddress[0].firstName,
        lastName: givenAddress[0].lastName,
        mobileNumber: givenAddress[0].phoneNumber,
        state: givenAddress[0].state,
        city: givenAddress[0].city,
        address: givenAddress[0].address,
        landmark: givenAddress[0].landmark,
        postalCode: givenAddress[0].zipCode,
      });
      setIsEdit(true);
    } else {
      setIsEdit(false);
      setDeliveryAddress((pre) => {
        return {
          ...pre,
          firstName: "",
          lastName: "",
          mobileNumber: "",
          state: "",
          city: "",
          address: "",
          landmark: "",
          postalCode: "",
        };
      });
    }
  }, [addressState.addressData, params]);

  useEffect(() => {
    if (authState.isLoggedIn && authState.token) {
      const token = localStorage.getItem("token");
      dispatch(AddressAction.isAddressFetch(true));
      fetch(`${process.env.REACT_APP_SERVER_DOMAIN}/address/getAddress`, {
        method: "POST",
        headers: {
          Authorization: "Bearer " + token,
          "content-type": "application/json",
          Accept: "application/json",
        },
      })
        .then(async (response) => {
          if (!response.ok) {
            const resData = await response.json();
            throw resData;
          }

          return response.json();
        })
        .then((resData) => {
          dispatch(AddressAction.setAddress(resData));
          dispatch(AddressAction.isAddressFetch(false));
        })
        .catch((err) => {
          console.log(err);
          dispatch(AddressAction.isAddressFetch(false));
        });
    } else {
      history.push("/login");
    }
  }, [dispatch, authState.isLoggedIn, authState.token, history]);

  const saveAddressHandler = () => {
    if (authState.isLoggedIn && authState.token) {
      dispatch(AddressAction.addressLoading(true));
      const token = authState.token;
      const saveAddress = {
        firstName: firstName.current.value,
        lastName: lastName.current.value,
        address: address.current.value,
        city: city.current.value,
        state: state.current.value,
        postalCode: postalCode.current.value,
        mobileNumber: mobileNumber.current.value,
        landmark: landmark.current.value,
      };
      console.log(saveAddress);
      let url = `${process.env.REACT_APP_SERVER_DOMAIN}/address/addAddress`,
        method = "POST";

      if (isEdit) {
        url = `${
          process.env.REACT_APP_SERVER_DOMAIN
        }/address/updateAddress/${params.addressId.slice(
          1,
          params.addressId.length
        )}`;
        method = "PUT";
      }

      fetch(url, {
        method: method,
        body: JSON.stringify(saveAddress),
        headers: {
          Authorization: "Bearer " + token,
          "content-type": "application/json",
          Accept: "application/json",
        },
      })
        .then(async (response) => {
          if (!response.ok) {
            const resData = await response.json();
            throw resData;
          }
          return response.json();
        })
        .then((resData) => {
          dispatch(AddressAction.setAddress(resData));
          dispatch(AddressAction.setDeliveryAddress(resData));
          setDeliveryAddress((pre) => {
            return {
              ...pre,
              firstName: "",
              lastName: "",
              mobileNumber: "",
              state: "",
              city: "",
              address: "",
              landmark: "",
              postalCode: "",
            };
          });
          dispatch(AddressAction.addressLoading(false));
          history.push("/address");
          if (isEdit) {
            toastifySuccess(
              "Address updated! Your delivery details have been refreshed.",
              "🏠"
            );
          } else {
            toastifySuccess(
              "Address saved! Your new location is securely stored for seamless deliveries.",
              "🏠"
            );
          }
          scrollToTop();
        })
        .catch(async (err) => {
          console.log(err.message);
          dispatch(AddressAction.addressLoading(false));
          toastifyError(err.message, "🚫");
        });
    } else {
      history.push("/login");
    }
  };

  return (
    <div className={styles.address}>
      <h1 className={styles.h1}>Shipping Address</h1>
      <div className={styles.address_container}>
        <div className={styles.address_container_card}>
          {console.log("address", addressState.isAddressFetch)}
          {!addressState.isAddressFetch ? (
            addressState.addressData.map((address) => {
              if (
                params.addressId &&
                addressState.addressData.length > 0 &&
                params.addressId.slice(1).toString() === address._id.toString()
              ) {
                return false;
              } else {
                return (
                  <AddressCard
                    className={styles.address_container_card_content}
                    address={address}
                    key={address._id}
                    addressId={address._id}
                  />
                );
              }
            })
          ) : (
            <l-helix
              size="100"
              speed="2.5"
              color="#e14d2a"
              style={{ marginTop: "5rem" }}
            ></l-helix>
          )}
        </div>
        <div className={styles.address_container_content}>
          <Card className={styles.address_container_content_card}>
            <h2 className={styles.h2}>Add New Address</h2>
            <form className={styles.form}>
              <div className={styles.form_container}>{formItem}</div>
              {!addressState.addressLoading ? (
                <button
                  type="submit"
                  className={styles.btn}
                  onClick={(e) => {
                    e.preventDefault();
                    saveAddressHandler();
                    setIsHovered(true);
                  }}
                >
                  Add Address
                </button>
              ) : (
                <button
                  type="submit"
                  className={styles.btn}
                  onMouseEnter={() => {
                    setIsHovered(true);
                  }}
                  onMouseLeave={() => setIsHovered(false)}
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                >
                  <l-dot-wave
                    size="30"
                    speed="2.5"
                    color={isHovered ? "#e14d2a" : "white"}
                  ></l-dot-wave>
                </button>
              )}
            </form>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default Address;

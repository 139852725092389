import { createSlice } from "@reduxjs/toolkit";

const initialProductState = {
  data: [],
  currentPage: 1,
  totalProducts: 0,
  itemsPerPage: 0,
  isProductFetch: false,
  productLoading: false,
};

const ProductSlice = createSlice({
  name: "product",
  initialState: initialProductState,
  reducers: {
    replaceActivity(state, action) {
      state.data = action.payload.productData;
      state.totalProducts = action.payload.totalProducts;
      state.itemsPerPage = action.payload.itemsPerPage;
    },
    setCurrentPage(state, action) {
      console.log(action.payload.currentPage);
      state.currentPage = action.payload.currentPage || 1;
    },
    dataFetched(state, action) {
      state.isProductFetch = action.payload;
    },
    productLoading(state, action) {
      state.productLoading = action.payload;
    },
    appendData(state, action) {
      const { productData, totalProducts, itemsPerPage } = action.payload;

      // Ensure action.payload.productData is an array before merging
      const newData = Array.isArray(productData) ? productData : [];

      return {
        ...state,
        data: [...state.data, ...newData],
        totalProducts: totalProducts,
        itemsPerPage: itemsPerPage || state.itemsPerPage, // Consider using the incoming value if available
      };
    },
    clearData(state) {
      state.data = [];
      state.totalProducts = 0;
      state.itemsPerPage = 0;
      state.currentPage = 1;
    },
  },
});

export const ProductAction = ProductSlice.actions;
export default ProductSlice.reducer;

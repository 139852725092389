import { ChevronLeft, ChevronRight } from "lucide-react";
import styles from "./_arrow.module.scss";

const Arrow = (props) => {
  const { className, style, onClick } = props;
  return props.left ? (
    <ChevronLeft
      className={`${className} ${styles.arrow}    `}
      style={{ ...style, display: "block", color: "#ffff" }}
      onClick={onClick}
    />
  ) : (
    <ChevronRight
      className={`${className} ${styles.arrow}    `}
      style={{ ...style, display: "block", color: "#ffff" }}
      onClick={onClick}
    />
  );
};

export default Arrow;

import { ProductAction } from "./product-slice";

export const filterTrunk = (filter, isClear = false, page = 1) => {
  return async (dispatch) => {
    const getFilterData = async () => {
      dispatch(ProductAction.productLoading(true));
      if (isClear) {
        console.log("=========================", isClear, "-------");
        dispatch(ProductAction.clearData());
      } else {
        dispatch(ProductAction.dataFetched(true));
      }
      return fetch(
        `${process.env.REACT_APP_SERVER_DOMAIN}/getInfo/product?page=${page}`,
        {
          method: "POST",
          body: JSON.stringify(filter),
          headers: {
            "content-type": "application/json",
            Accept: "application/json",
          },
        }
      )
        .then(async (response) => {
          if (!response.ok) {
            const resData = await response.json();
            throw new Error(resData);
          }
          return response.json();
        })
        .catch((err) => {
          console.log(err);
          dispatch(ProductAction.productLoading(false));
        });
    };

    try {
      const filterDate = await getFilterData();
      console.log(filterDate, isClear);
      if (isClear) {
        dispatch(ProductAction.replaceActivity(filterDate));
        dispatch(ProductAction.setCurrentPage({ currentPage: 1 }));
      } else {
        dispatch(ProductAction.appendData(filterDate));
        dispatch(ProductAction.dataFetched(false));
      }
      dispatch(ProductAction.productLoading(false));
    } catch (error) {
      console.log(error);
      dispatch(ProductAction.productLoading(false));
      if (!isClear) {
        dispatch(ProductAction.dataFetched(false));
      }
    }
  };
};

import { createSlice } from "@reduxjs/toolkit";

const filterInitialState = {
  filter: {
    materialType: null,
    soundType: null,
    scaleType: null,
    price: null,
    heading: null,
    filterLoading: false,
    isFilter: false,
  },
};

const FilterSlice = createSlice({
  name: "filter",
  initialState: filterInitialState,
  reducers: {
    setFilter(state, action) {
      console.log(action.payload);
      state.filter.materialType =
        action.payload.materialType || state.filter.materialType;
      state.filter.soundType = action.payload.soundType;
      state.filter.scaleType = action.payload.scaleType;
      state.filter.price = action.payload.price;
      state.filter.heading = action.payload.heading;
      state.filter.isFilter = action.payload.isFilter;
    },
    clearFilter(state, action) {
      state.filter.materialType = null;
      state.filter.soundType = null;
      state.filter.scaleType = null;
      state.filter.price = null;
      state.filter.heading = null;
    },
    filterLoading(state, action) {
      state.filter.filterLoading = action.payload;
    },
  },
});

export const FilterAction = FilterSlice.actions;
export default FilterSlice.reducer;

import styles from "./_product.module.scss";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useSelector } from "react-redux";
import FilterForm from "./FilterForm/FilterForm";
import ProductCard from "./ProductCard/ProductCard";
import { dotWave, helix } from "ldrs";
dotWave.register();
helix.register();

const Product = () => {
  const filterState = useSelector((state) => state.FilterSlice);
  const productState = useSelector((state) => state.ProductSlice);
  const history = useHistory();

  const productDetailNavigator = (id) => {
    history.push("/product/" + id);
  };

  return (
    <div className={styles.productFilter}>
      {/* <h1 className={styles.productFilter_h1}>
        {filterState.filter.materialType || "All Products"}
      </h1> */}
      <h2 className={styles.productFilter_h2}>
        {filterState.filter.materialType?.toUpperCase() || "ALL PRODUCTS"}
      </h2>
      <div className={styles.productFilter_content}>
        <FilterForm />
        <div className={styles.productFilter_content_item}>
          {(productState.isProductFetch || productState.data?.length !== 0) &&
            productState.data?.map((product, index) => (
              <ProductCard
                product={product}
                key={product._id}
                id={product._id}
                onClick={() => productDetailNavigator(product._id)}
              />
            ))}
          {console.log(
            "checkingg ====",
            productState.productLoading,
            productState.data.length === 0
          )}
          {productState.productLoading && productState.data.length === 0 && (
            <l-helix
              size="60"
              speed="2.5"
              color="#e14d2a"
              style={{ marginTop: "3.5rem", marginBottom: "3.5rem" }}
            ></l-helix>
          )}
          {!productState.productLoading && productState.totalProducts === 0 && (
            <h1 className={styles.noProduct}>NO PRODUCT FOUND</h1>
          )}
        </div>
      </div>
      {productState.isProductFetch && (
        <l-dot-wave
          size="60"
          speed="1.3"
          color="#e14d2a"
          style={{ alignSelf: "center", marginBottom: "10px" }}
        ></l-dot-wave>
      )}
    </div>
  );
};

export default Product;

import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom";
import Card from "../../Utils/Card/Card";
import styles from "./_checkout.module.scss";
import { useEffect, useState } from "react";
import OrderItem from "./OrderItem/OrderItem";
import DeliveryCard from "./DeliveryCard/DeliveryCard";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../Utils/button/Button";
import { CheckoutAction } from "../../../Store/checkout-slice";
import { toastifyError } from "../../../Helpers/notificationToastify";

const Checkout = (props) => {
  const params = useParams();
  const addressState = useSelector((state) => state.AddressSlice);
  const [deliveryAddress, setDeliveryAddress] = useState(null);
  const history = useHistory();
  const cartState = useSelector((state) => state.CartSlice);
  const authState = useSelector((state) => state.Auth);
  const checkoutState = useSelector((state) => state.CheckoutSlice);
  const dispatch = useDispatch();
  const [isHovered, setIsHovered] = useState(false);

  const placedOrder = async () => {
    try {
      if (authState.isLoggedIn && authState.token && deliveryAddress) {
        console.log(deliveryAddress, deliveryAddress._id);
        dispatch(CheckoutAction.checkoutLoading(true));
        const response = await fetch(
          `${process.env.REACT_APP_SERVER_DOMAIN}/qroPay/pay?deliveryId=${deliveryAddress?._id}`,
          {
            method: "POST",
            headers: {
              Authorization: "Bearer " + authState.token,
            },
          }
        );

        if (response.ok) {
          const responseData = await response.json();
          console.log(responseData);
          // Assuming responseData contains a payment URL
          if (responseData && responseData.payment_url) {
            // Open the payment URL in a new tab
            window.open(responseData.payment_url, "_self");
            dispatch(CheckoutAction.checkoutLoading(false));
          } else {
            // Handle missing payment URL
            console.error("Payment URL not found in response data");
            throw new Error("Payment URL not found");
          }
        } else {
          // Handle other cases if needed
          console.error("Error initiating payment:", response.statusText);
          throw new Error("Error initiating payment: " + response.statusText);
        }
      } else {
        history.push("/login");
      }
    } catch (error) {
      console.error("Error:", error);
      toastifyError(error.message, "🚫");
      dispatch(CheckoutAction.checkoutLoading(false));
    }
  };

  useEffect(() => {
    if (authState.isLoggedIn && authState.token) {
      if (params.addressId && addressState.addressData.length > 0) {
        if (cartState.payment.total <= 0) {
          history.push("/cart");
        } else {
          const addressId = params.addressId.slice(1, params.addressId.length);
          const givenAddress = addressState.addressData.filter(
            (address) => addressId.toString() === address._id.toString()
          );
          setDeliveryAddress(givenAddress[0]);
        }
      } else {
        history.push("/address");
      }
    } else {
      history.push("/login");
    }
  }, [
    params.addressId,
    addressState.addressData,
    history,
    authState.isLoggedIn,
    authState.token,
    cartState.payment.total,
  ]);

  return (
    <div className={styles.checkout}>
      <h1 className={styles.h1}>Checkout</h1>
      <div className={styles.checkout_container1}>
        <div className={styles.checkout_container1_deliveryCard}>
          <h2 className={styles.h2}>Your Deliver Address</h2>
          <div className={styles.checkout_container1_deliveryCard_card}>
            <DeliveryCard
              address={deliveryAddress}
              addressId={deliveryAddress?._id}
            />
          </div>
        </div>
        <div className={styles.checkout_container1_order}>
          <h2 className={styles.h2}>Order Items</h2>
          <div className={styles.checkout_container1_order_card}>
            {Object.entries(cartState.cart).map(([productId, addedProduct]) => (
              <OrderItem
                key={productId}
                productId={productId}
                addedProduct={addedProduct}
                prod={cartState.cartView[productId]}
              />
            ))}
          </div>
        </div>
      </div>
      <Card className={styles.checkout_container2}>
        <ul className={styles.list}>
          <li className={styles.list_item}>
            <span className={styles.list_item_key}>Subtotal</span>
            <span className={styles.list_item_value}>
              ₹{cartState.payment.subtotal}
            </span>
          </li>
          <li className={styles.list_item}>
            <span className={styles.list_item_key}>Delivery Charges</span>
            <span className={styles.list_item_value}>
              {cartState.payment.deliveryCharge
                ? `₹ ${cartState.payment.deliveryCharge}`
                : "Free shipping"}
            </span>
          </li>
          <li className={styles.list_item}>
            <span className={styles.list_item_key}>Discount</span>
            <span className={styles.list_item_value}>
              {cartState.payment.discount
                ? `- ₹${cartState.payment.discount}`
                : "Zero"}
            </span>
          </li>
          <li className={styles.list_item}>
            <span className={styles.list_item_key}>Tax</span>
            <span className={styles.list_item_value}>
              {cartState.payment.tax ? `₹${cartState.payment.tax}` : "Zero"}
            </span>
          </li>
          <li className={`${styles.list_item} ${styles.list_item_total}`}>
            <span
              className={`${styles.list_item_key} ${styles.list_item_total_key}`}
            >
              Total
            </span>
            <span
              className={`${styles.list_item_value} ${styles.list_item_total_value}`}
            >
              ₹{cartState.payment.total}
            </span>
          </li>
        </ul>
        {!checkoutState.checkoutLoading ? (
          <Button
            className={`${styles.btn} ${styles.btn_placeOrder}`}
            onClick={() => {
              placedOrder();
              setIsHovered(true);
            }}
          >
            Place Order
          </Button>
        ) : (
          <Button
            className={`${styles.btn} ${styles.btn_placeOrder}`}
            onMouseEnter={() => {
              setIsHovered(true);
            }}
            onMouseLeave={() => setIsHovered(false)}
          >
            <l-dot-wave
              size="35"
              speed="2.5"
              color={isHovered ? "#e14d2a" : "white"}
            ></l-dot-wave>
          </Button>
        )}
      </Card>
    </div>
  );
};

export default Checkout;

import Card from "../../../Utils/Card/Card";
import styles from "./_sectionCarousel.module.scss";
import fluteImage from "../../../../Images/flute.png";
import Button from "../../../Utils/button/Button";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { calculateDiscountPercentage } from "../../../../Helpers/discountPercentage";

const SectionCarouselCard = (props) => {
  const history = useHistory();
  const discountPercentage = calculateDiscountPercentage(
    props.data.price,
    props.data.discount
  );

  const discountPrice = props.data.price - props.data.discount;

  const GoToProductDetails = (prodId) => {
    history.push("/product/" + prodId);
  };

  return (
    <Card
      className={styles.SectionCarouselCard_Container}
      onClick={() => {
        GoToProductDetails(props.productId);
      }}
    >
      <div className={styles.SectionCarouselCard_Container_image}>
        <img src={props.data.imageUrl || fluteImage} alt="flute"></img>
      </div>
      <h2 className={styles.SectionCarouselCard_Container_h2}>
        {props.data.heading || "Flute Heading"}
      </h2>
      <div className={styles.SectionCarouselCard_Container_Price}>
        <span className={styles.SectionCarouselCard_Container_Price_new}>
          ₹{discountPrice || "New_price"}
        </span>
        <span className={styles.SectionCarouselCard_Container_Price_old}>
          ₹{props.data.price || "Old_price"}
        </span>
      </div>
      <Button
        className={styles.SectionCarouselCard_Container_btn}
        onClick={() => {
          GoToProductDetails(props.productId);
        }}
      >
        Buy Now
      </Button>

      <div className={styles.SectionCarouselCard_Container_discount}>
        <p>{discountPercentage || "43"}%</p>
        <p>OFF</p>
      </div>
    </Card>
  );
};

export default SectionCarouselCard;

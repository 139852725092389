import React, { Fragment } from "react";
import Button from "../button/Button";
import styles from "./_nav.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { backDropActions } from "../../../Store/backdrop-slice";
import { FilterAction } from "../../../Store/filter-slice";
import { ProductAction } from "../../../Store/product-slice";
import user from "../../../Images/user.png";
import DropDown from "./dropDown/DropDown";
import { dotPulse } from "ldrs";

dotPulse.register();

const Nav = (props) => {
  const Auth = useSelector((state) => state.Auth);
  const navClasses = `${styles.nav} ${props.className ? props.className : ""}`;
  const userState = useSelector((state) => state.UserSlice);

  const dispatch = useDispatch();

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <nav className={navClasses}>
      <ul className={styles.list}>
        <Fragment>
          <li className={styles.items}>
            <Link
              className={styles.link}
              to="/products"
              onClick={() => {
                // console.log("fry bamboo is clicked");
                dispatch(ProductAction.setCurrentPage({ currentPage: 1 }));
                dispatch(backDropActions.mobileNavHandler(false));
                dispatch(FilterAction.clearFilter());
                dispatch(
                  FilterAction.setFilter({ materialType: "fry Bamboo" })
                );
              }}
            >
              Fry Bamboo
            </Link>
          </li>
          <li className={styles.items}>
            <Link
              className={styles.link}
              to="/products"
              onClick={() => {
                dispatch(ProductAction.setCurrentPage({ currentPage: 1 }));
                dispatch(backDropActions.mobileNavHandler(false));
                dispatch(FilterAction.clearFilter());
                dispatch(FilterAction.setFilter({ materialType: "acrylic" }));
              }}
            >
              Acrylic
            </Link>
          </li>
        </Fragment>
        {!Auth.isLoggedIn && (
          <Fragment>
            <li className={`${styles.items} ${styles.margin_left_auto}`}>
              <Link className={styles.link} to="/login">
                <Button
                  onClick={() => {
                    dispatch(backDropActions.mobileNavHandler(false));
                    scrollToTop();
                  }}
                  className={`${styles.button} ${styles.button_login}`}
                >
                  Login
                </Button>
              </Link>
            </li>
            <li className={styles.items}>
              <Link className={styles.link} to="/signup">
                <Button
                  onClick={() => {
                    dispatch(backDropActions.mobileNavHandler(false));
                    scrollToTop();
                  }}
                  className={`${styles.button} ${styles.button_signUp}`}
                >
                  Signup
                </Button>
              </Link>
            </li>
          </Fragment>
        )}
        {Auth.isLoggedIn && (
          <div className={`${styles.margin_left_auto}`}>
            <li className={`${styles.items} ${styles.dropItem}`}>
              <img src={user} alt="user" width={30} height={30}></img>
              {!userState.isUser ? (
                <span>{userState.name}</span>
              ) : (
                <l-dot-pulse
                  size="43"
                  speed="1.3"
                  color="#BFCFE7"
                ></l-dot-pulse>
              )}
            </li>
            <DropDown className={styles.dropDown} />
          </div>
        )}
      </ul>
    </nav>
  );
};

export default Nav;

import { RandomProductAction } from "./randomProduct-slice";

export const randomProductTrunk = (identify) => {
  return async (dispatch) => {
    const getRandomProduct = async () => {
      dispatch(RandomProductAction.randomProductLoading(true));
      dispatch(RandomProductAction.randomProductFetched(false));

      return fetch(`${process.env.REACT_APP_SERVER_DOMAIN}/getInfo/product`, {
        method: "GET",
        headers: {
          "content-type": "application/json",
          Accept: "application/json",
        },
      })
        .then((response) => {
          if (!response.ok) {
            const resData = response.json();
            throw new Error(resData);
          }
          return response.json();
        })
        .catch((err) => {
          console.log(err);
          dispatch(RandomProductAction.randomProductLoading(false));
        });
    };

    try {
      const randomProduct = await getRandomProduct();
      if (identify === "Best Selling") {
        dispatch(RandomProductAction.setBestSelling(randomProduct));
      } else if (identify === "New Arrival" || identify === "Similar Product") {
        dispatch(RandomProductAction.setNewArrival(randomProduct));
      }
      dispatch(RandomProductAction.randomProductLoading(false));
      dispatch(RandomProductAction.randomProductFetched(true));
    } catch (error) {
      // console.log(error);
      dispatch(RandomProductAction.randomProductLoading(false));
    }
  };
};

import { Fragment, useEffect } from "react";
import styles from "./_app.module.scss";
import BackDrop from "./Components/Modal/BackDrop";
import reactRouterDom from "react-dom";
import Header from "./Components/Utils/header/Header";
import { Switch } from "react-router-dom/cjs/react-router-dom.min";
import { Route } from "react-router-dom/cjs/react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Signup from "./Components/Pages/SignUp/Signup";
import Login from "./Components/Pages/Login/Login";
import { AuthActions } from "./Store/auth-slice";
import { UserActions } from "./Store/user-slice";
import HomePage from "./Components/Pages/HomePage/HomePage";
// import { ProductAction } from "./Store/product-slice";
import ProductDetails from "./Components/Pages/ProductDetails/ProductDetails";
import Footer from "./Components/FeatureComponents/Footer/Footer";
import Address from "./Components/Pages/Address/Address";
import Checkout from "./Components/Pages/Checkout/Checkout";
import Cart from "./Components/Pages/Cart/Cart";
import Order from "./Components/Pages/Order/Order";
import Product from "./Components/Pages/Product/Product";
import { cartTrunk } from "./Store/cart-action";
import MobileNav from "./Components/Utils/Mobile/MobileNav";
import Toastify from "./Components/Utils/Toastify/Toastify";
import { CartAction } from "./Store/cart-slice";
import { toastifySuccess } from "./Helpers/notificationToastify";
import { OrderAction } from "./Store/order-slice";
import { fetchUser } from "./Store/user-action";

const calcRemTime = (expTime) => {
  const currTime = new Date().getTime();
  const expirationTime = new Date(+expTime).getTime();
  console.log(currTime, expirationTime, expTime);
  return expirationTime - currTime;
};

const getStoredToken = () => {
  const storedToken = localStorage.getItem("token") || null;
  const storedExpirationTime = localStorage.getItem("expirationTime") || 0;
  const remainingTime = calcRemTime(storedExpirationTime);
  if (remainingTime <= 3600) {
    console.log(remainingTime, storedExpirationTime);
    localStorage.removeItem("token");
    localStorage.removeItem("expirationTime");

    return { token: null };
  }

  return {
    token: storedToken,
    duration: remainingTime,
  };
};

function App() {
  const authState = useSelector((state) => state.Auth);
  const dispatch = useDispatch();

  useEffect(() => {
    const tokenData = getStoredToken();
    console.log("tokenData", tokenData);
    if (tokenData.token) {
      console.log("login user ==== token: ", tokenData.token);
      dispatch(
        AuthActions.login({
          token: tokenData.token,
          time: tokenData.duration,
          timer: setTimeout(() => {
            // console.log("timeOut");
            dispatch(AuthActions.logout());
          }, tokenData.duration),
        })
      );
      dispatch(fetchUser());
    } else {
      dispatch(AuthActions.logout());
    }
  }, [dispatch]);

  useEffect(() => {
    const tokenData = getStoredToken();
    if (authState.isLoggedIn && authState.token && tokenData.token !== null) {
      const token = authState.token;
      console.log(authState.isLoggedIn, authState.token);
      dispatch(cartTrunk(token, 0, "getCart"));
      dispatch(cartTrunk(token, 1, "cartView"));
    }
  }, [dispatch, authState.token, authState.isLoggedIn]);

  useEffect(() => {
    if (!authState.isLoggedIn) {
      console.log("cart is cleared");
      dispatch(CartAction.clearCartData());
      dispatch(OrderAction.clearOrder());
    }
  }, [authState.isLoggedIn, dispatch]);

  useEffect(() => {
    const getUser = () => {
      fetch(`${process.env.REACT_APP_SERVER_DOMAIN}/auth/success`, {
        method: "GET",
        credentials: "include", // Send cookies
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          console.log(response);
          if (response.status === 200) {
            return response.json();
          }
          throw new Error("Authentication failed");
        })
        .then((resData) => {
          const expirationTime = new Date().getTime() + +60 * 60 * 1000;
          localStorage.setItem("expirationTime", expirationTime);
          const remainingTime = calcRemTime(expirationTime);
          dispatch(
            AuthActions.login({
              token: resData.token,
              time: expirationTime,
              timer: setTimeout(() => {
                // console.log("timeOut");
                dispatch(AuthActions.logout());
                dispatch(UserActions.userLogOut());
              }, remainingTime),
            })
          );
          dispatch(
            UserActions.replaceUser({
              name: resData.name,
            })
          );
          console.log(resData);
          toastifySuccess("Welcome back! You're securely logged in.", "🔑");
        })
        .catch((err) => {
          console.log(err);
        });
    };
    if (!authState.isLoggedIn) {
      getUser();
    }
  }, [dispatch, authState.isLoggedIn]);

  return (
    <Fragment>
      {reactRouterDom.createPortal(
        <Toastify />,
        document.getElementById("notification")
      )}
      {reactRouterDom.createPortal(
        <BackDrop></BackDrop>,
        document.getElementById("modal")
      )}
      <Header></Header>
      <MobileNav />
      <Switch>
        <Route path="/" exact>
          <HomePage />
        </Route>
        <Route path="/product/:productId">
          <main className={styles.main}>
            <ProductDetails />
          </main>
        </Route>
        <Route path="/products">
          <main className={styles.main}>
            <Product />
          </main>
        </Route>
        {!authState.isLoggedIn && (
          <Route path="/login">
            <main className={styles.main}>
              <Login></Login>
            </main>
          </Route>
        )}
        {!authState.isLoggedIn && (
          <Route path="/signup">
            <main className={styles.main}>
              <Signup></Signup>
            </main>
          </Route>
        )}
        {authState.isLoggedIn && (
          <Route path="/editAddress/:addressId">
            <main className={styles.main}>
              <Address />
            </main>
          </Route>
        )}
        {authState.isLoggedIn && (
          <Route path="/Address">
            <main className={styles.main}>
              <Address />
            </main>
          </Route>
        )}
        {authState.isLoggedIn && (
          <Route path="/checkout/:addressId">
            <main className={styles.main}>
              <Checkout />
            </main>
          </Route>
        )}
        {authState.isLoggedIn && (
          <Route path="/cart">
            <main className={styles.main}>
              <Cart />
            </main>
          </Route>
        )}
        {authState.isLoggedIn && (
          <Route path="/order">
            <main className={styles.main}>
              <Order />
            </main>
          </Route>
        )}
        <Route path="*">
          <HomePage />
        </Route>
      </Switch>
      <Footer />
    </Fragment>
  );
}

export default App;

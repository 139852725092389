import { useEffect, useState } from "react";
import Card from "../../../Utils/Card/Card";
import styles from "./_filterForm.module.scss";
import Button from "../../../Utils/button/Button";
import { useDispatch, useSelector } from "react-redux";
import { filterTrunk } from "../../../../Store/filter-action";
import { FilterAction } from "../../../../Store/filter-slice";
import { ProductAction } from "../../../../Store/product-slice";

const FilterForm = (props) => {
  const filterState = useSelector((state) => state.FilterSlice);
  const productState = useSelector((state) => state.ProductSlice);
  const [filterData, setFilterData] = useState({
    soundType: "",
    scaleType: "",
  });
  const dispatch = useDispatch();

  const handleRadioChange = (e) => {
    const { name, value } = e.target;
    setFilterData((prevEditProduct) => ({
      ...prevEditProduct,
      [name]: value,
    }));
  };

  const clearFilter = () => {
    const data = {
      ...filterState.filter,
      soundType: "",
      scaleType: "",
      isFilter: false,
    };
    dispatch(ProductAction.setCurrentPage({ currentPage: 1 }));
    dispatch(FilterAction.setFilter(data));
    setFilterData((pre) => {
      return {
        ...pre,
        soundType: "",
        scaleType: "",
      };
    });
  };

  const filterHandler = () => {
    if (filterData.soundType === "" && filterData.scaleType === "") {
      return;
    }
    console.log(filterData);
    const data = {
      ...filterState.filter,
      soundType: filterData.soundType,
      scaleType: filterData.scaleType,
      isFilter: true,
    };
    dispatch(FilterAction.setFilter(data));
    setFilterData((pre) => {
      return {
        ...pre,
        soundType: "",
        scaleType: "",
      };
    });
  };

  useEffect(() => {
    // console.log("new page loaded");
    dispatch(filterTrunk(filterState.filter, true, 1));
    // scrollToTop();
    window.scroll({ top: 0, left: 0 });
  }, [dispatch, filterState.filter]);

  useEffect(() => {
    if (productState.currentPage > 1) {
      console.log("new data get loaded");
      dispatch(
        filterTrunk(filterState.filter, false, productState.currentPage)
      );
    }
  }, [dispatch, filterState.filter, productState.currentPage]);

  // useEffect(() => {
  //   const handleInfiniteScroll = async () => {
  //     try {
  //       if (
  //         !productState.isProductFetch &&
  //         window.innerHeight + document.documentElement.scrollTop + 200 >=
  //           document.documentElement.scrollHeight &&
  //         productState.data.length < productState.totalProducts
  //       ) {
  //         dispatch(ProductAction.dataFetched(true));
  //         dispatch(
  //           ProductAction.setCurrentPage({
  //             currentPage: productState.currentPage + 1,
  //           })
  //         );
  //       }
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   };
  //   window.addEventListener("scroll", handleInfiniteScroll);
  //   return () => {
  //     window.removeEventListener("scroll", handleInfiniteScroll);
  //   };
  // }, [
  //   dispatch,
  //   productState.currentPage,
  //   productState.data.length,
  //   productState.totalProducts,
  //   productState.isProductFetch,
  // ]);

  return (
    <Card className={styles.filter}>
      {filterState.filter.isFilter && (
        <div className={styles.isFilter}>
          {filterState.filter.scaleType && (
            <span className={styles.isFilter_filter}>
              {filterState.filter.scaleType}
            </span>
          )}
          {filterState.filter.soundType && (
            <span className={styles.isFilter_filter}>
              {filterState.filter.soundType}
            </span>
          )}
        </div>
      )}

      <h2 className={styles.h2}>Filter</h2>
      <form className={styles.form}>
        <div className={styles.form_group}>
          <p className={styles.p}>Sound Type</p>
          <label>
            <input
              type="radio"
              name="soundType"
              value="base"
              onChange={handleRadioChange}
              checked={filterData.soundType === "base"}
            />{" "}
            Base
          </label>
          <label>
            <input
              type="radio"
              name="soundType"
              value="middle"
              onChange={handleRadioChange}
              checked={filterData.soundType === "middle"}
            />{" "}
            Middle
          </label>
          <label>
            <input
              type="radio"
              name="soundType"
              value="shank"
              onChange={handleRadioChange}
              checked={filterData.soundType === "shank"}
            />{" "}
            Shank
          </label>
          <label>
            <input
              type="radio"
              name="soundType"
              value="small"
              onChange={handleRadioChange}
              checked={filterData.soundType === "small"}
            />{" "}
            Small
          </label>
        </div>
        <div className={styles.form_group}>
          <p className={styles.p}>Scale Type</p>

          <label>
            <input
              type="radio"
              name="scaleType"
              value="A"
              onChange={handleRadioChange}
              checked={filterData.scaleType === "A"}
            />{" "}
            A
          </label>
          <label>
            <input
              type="radio"
              name="scaleType"
              value="A#"
              onChange={handleRadioChange}
              checked={filterData.scaleType === "A#"}
            />{" "}
            A#
          </label>
          <label>
            <input
              type="radio"
              name="scaleType"
              value="B"
              onChange={handleRadioChange}
              checked={filterData.scaleType === "B"}
            />{" "}
            B
          </label>
          <label>
            <input
              type="radio"
              name="scaleType"
              value="B#"
              onChange={handleRadioChange}
              checked={filterData.scaleType === "B#"}
            />{" "}
            B#
          </label>
          <label>
            <input
              type="radio"
              name="scaleType"
              value="C"
              onChange={handleRadioChange}
              checked={filterData.scaleType === "C"}
            />{" "}
            C
          </label>
          <label>
            <input
              type="radio"
              name="scaleType"
              value="C#"
              onChange={handleRadioChange}
              checked={filterData.scaleType === "C#"}
            />{" "}
            C#
          </label>
          <label>
            <input
              type="radio"
              name="scaleType"
              value="D"
              onChange={handleRadioChange}
              checked={filterData.scaleType === "D"}
            />{" "}
            D
          </label>
          <label>
            <input
              type="radio"
              name="scaleType"
              value="D#"
              onChange={handleRadioChange}
              checked={filterData.scaleType === "D#"}
            />{" "}
            D#
          </label>
          <label>
            <input
              type="radio"
              name="scaleType"
              value="E"
              onChange={handleRadioChange}
              checked={filterData.scaleType === "E"}
            />{" "}
            E
          </label>
          <label>
            <input
              type="radio"
              name="scaleType"
              value="E#"
              onChange={handleRadioChange}
              checked={filterData.scaleType === "E#"}
            />{" "}
            E#
          </label>
          <label>
            <input
              type="radio"
              name="scaleType"
              value="F"
              onChange={handleRadioChange}
              checked={filterData.scaleType === "F"}
            />{" "}
            F
          </label>
          <label>
            <input
              type="radio"
              name="scaleType"
              value="F#"
              onChange={handleRadioChange}
              checked={filterData.scaleType === "F#"}
            />{" "}
            F#
          </label>
          <label>
            <input
              type="radio"
              name="scaleType"
              value="G"
              onChange={handleRadioChange}
              checked={filterData.scaleType === "G"}
            />{" "}
            G
          </label>
          <label>
            <input
              type="radio"
              name="scaleType"
              value="G#"
              onChange={handleRadioChange}
              checked={filterData.scaleType === "G#"}
            />{" "}
            G#
          </label>
        </div>
      </form>
      <Button className={styles.btn} onClick={filterHandler}>
        Apply
      </Button>
      {filterState.filter.isFilter && (
        <Button className={styles.btn} onClick={clearFilter}>
          Clear
        </Button>
      )}
    </Card>
  );
};

export default FilterForm;

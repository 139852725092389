import styles from "./_sectionCarousel.module.scss";
import sectionSeparator from "../../../Images/flute.png";
import SectionCarouselCard from "./SectionCarouselCard/SectionCarouselCard";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import { flutes } from "../../../Data/data";
import Arrow from "../../Utils/Arrow/Arrow";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { randomProductTrunk } from "../../../Store/randomProduct-action";
import { waveform } from "ldrs";

waveform.register();

const SectionCarousel = (props) => {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    autoplaySpeed: 2000,
    pauseOnHover: true,
    prevArrow: <Arrow left={true} />,
    nextArrow: <Arrow right={true} />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 800,
        settings: {
          centerMode: true,
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 650,
        settings: {
          centerMode: false,
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 560,
        settings: {
          centerMode: true,
          centerPadding: "70px",
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 450,
        settings: {
          centerMode: true,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 400,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const dispatch = useDispatch();
  const randomProductState = useSelector((state) => state.RandomProductSlice);

  useEffect(() => {
    dispatch(randomProductTrunk(props.sectionName));
  }, [dispatch, props.sectionName]);

  return (
    <div className={styles.sectionContainer}>
      {/* <h1 className={styles.sectionContainer_h1}>
        {props.sectionName || "Best Selling"}
      </h1> */}
      <h2 className={styles.sectionContainer_h2}>
        {props.sectionName || "Best Selling"}
      </h2>
      <div className={styles.sectionContainer_imageContainer}>
        <img src={sectionSeparator} alt={props.sectionName || "image"} />
      </div>

      <div className={styles.sectionContainer_carousel}>
        {randomProductState.isRandomProductFetch ? (
          <Slider {...settings}>
            {props.sectionName === "Best Selling" &&
              randomProductState.isRandomProductFetch &&
              randomProductState.BestSelling.length !== 0 &&
              randomProductState.BestSelling.map((item, i) => (
                <SectionCarouselCard data={item} key={i} productId={item._id} />
              ))}
            {(props.sectionName === "New Arrival" ||
              props.sectionName === "Similar Product") &&
              randomProductState.isRandomProductFetch &&
              randomProductState.NewArrival.length !== 0 &&
              randomProductState.NewArrival.map((item, i) => (
                <SectionCarouselCard data={item} key={i} productId={item._id} />
              ))}
          </Slider>
        ) : (
          <l-waveform
            size="60"
            stroke="3.5"
            speed="1"
            color="#e14d2a"
            style={{ display: "block", margin: "0 auto" }}
          ></l-waveform>
        )}
      </div>
    </div>
  );
};

export default SectionCarousel;

import { UserActions } from "./user-slice.js";

export const fetchUser = () => {
  return async (dispatch) => {
    const fetchData = async () => {
      dispatch(UserActions.isUser(true));
      const token = localStorage.getItem("token");

      const response = await fetch(
        `${process.env.REACT_APP_SERVER_DOMAIN}/user/userDetails`,
        {
          method: "GET",
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
      if (!response.ok) {
        const errData = await response.json();
        throw new Error(errData);
      }
      const data = response.json();
      return data;
    };

    try {
      const userData = await fetchData();
      console.log(userData);
      dispatch(
        UserActions.replaceUser({
          name: userData.name,
        })
      );
      dispatch(UserActions.isUser(false));
    } catch (err) {
      console.log(err);
      dispatch(UserActions.isUser(false));
      throw err;
    }
  };
};

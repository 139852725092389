import Card from "../../../Utils/Card/Card";
import styles from "./_orderCard.module.scss";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const OrderCard = (props) => {
  const history = useHistory();
  const productDetailNavigator = (id) => {
    history.push("/product/" + id);
  };
  const getDate = (date) => {
    const options = {
      month: "short",
      day: "2-digit",
      year: "numeric",
    };
    date = new Date(date);
    return new Intl.DateTimeFormat(navigator.language, options).format(date);
  };
  return (
    <Card className={styles.order}>
      <div className={styles.date_container}>
        <span className={styles.date_icon}>📅</span>
        <span className={styles.date}>{getDate(props.product.orderDate)}</span>
      </div>
      <div className={styles.orderItem}>
        {props.product.orderItems.map((orderItem) => {
          return (
            <Card
              className={styles.orderCard}
              key={orderItem.productId}
              onClick={() => {
                productDetailNavigator(orderItem.productId);
              }}
            >
              <div className={styles.imgContainer}>
                <img src={orderItem.imageUrl} alt="flute" />
              </div>
              <div className={styles.orderCard_content}>
                <h2 className={styles.h2}>{orderItem.heading}</h2>
                <h2 className={styles.price}>₹{orderItem.productPrice}</h2>
                <h2 className={styles.price}>{orderItem.quantity}</h2>
                <h2 className={styles.status}>{props.product.orderStatus}</h2>
              </div>
            </Card>
          );
        })}
      </div>
      <Card className={styles.PaymentDetails}>
        <h2 className={styles.h2}>Price Details</h2>
        <ul className={styles.list}>
          <li className={styles.list_item}>
            <span className={styles.list_item_key}>SubTotal</span>
            <span className={styles.list_item_value}>
              ₹{props.product.subTotal}
            </span>
          </li>
          <li className={styles.list_item}>
            <span className={styles.list_item_key}>Discount</span>
            <span className={styles.list_item_value}>
              {`- ₹${props.product.discount}`}
            </span>
          </li>
          <li className={styles.list_item}>
            <span className={styles.list_item_key}>Delivery Charges</span>
            <span className={styles.list_item_value}>
              {props.product.deliveryCharge !== 0
                ? `₹${props.product.deliveryCharge}`
                : "Free"}
            </span>
          </li>
          <li className={`${styles.list_item} ${styles.list_item_total}`}>
            <span
              className={`${styles.list_item_key} ${styles.list_item_total_key}`}
            >
              Total Amount
            </span>
            <span
              className={`${styles.list_item_value} ${styles.list_item_total_value}`}
            >
              ₹{props.product.totalAmount}
            </span>
          </li>
        </ul>
      </Card>
    </Card>
  );
};

export default OrderCard;

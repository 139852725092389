import Card from "../../../Utils/Card/Card";
import styles from "./_orderItem.module.scss";
import img from "../../../../Images/flute.png";
import images from "../../../../Helpers/importColorImages";

const OrderItem = (props) => {
  const discountPrice = props.prod?.price - props.prod?.discount;
  const imageKeys = Object.keys(images);
  return (
    <Card className={styles.orderItem}>
      <div className={styles.orderItem_imgContainer}>
        <img src={props.prod?.imageUrl || img} alt="Flute"></img>
      </div>
      <div className={styles.orderItem_product}>
        <h2 className={styles.h2}>{props.prod?.heading || "Error"}</h2>
        <p className={styles.p}>
          Item:<span>₹{discountPrice || "Error"}</span>
          <span className={styles.oldPrice}>
            {props.prod?.price || "Error"}
          </span>
        </p>
        <p className={styles.p}>
          Hand:<span>{props.addedProduct.hand === 0 ? "Left" : "Right"}</span>
        </p>
        <p className={styles.p}>
          Finger:
          <span>
            {props.addedProduct.finger === 0
              ? "Customized"
              : "Regular" || "Error"}
          </span>
        </p>
        <p className={styles.p}>
          color:
          <span>
            T-
            {props.addedProduct.color === imageKeys.length - 1
              ? props.addedProduct.color + 1
              : props.addedProduct.color + 1}
          </span>
        </p>
        <p className={styles.p}>
          Quantity:<span>{props.addedProduct.quantity}</span>
        </p>
        <p className={styles.p}>
          Total:
          <span>₹{discountPrice * props.addedProduct.quantity}</span>
        </p>
      </div>
    </Card>
  );
};

export default OrderItem;

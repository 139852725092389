import styles from "./_addressCard.module.scss";
import Card from "../../../Utils/Card/Card";
import Button from "../../../Utils/button/Button";
import { MapPin, Phone } from "lucide-react";
import { Trash2, FileEdit } from "lucide-react";
import { useDispatch, useSelector } from "react-redux";
import { AddressAction } from "../../../../Store/address-slice";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { toastifySuccess } from "../../../../Helpers/notificationToastify";
import { useState } from "react";

const AddressCard = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const authState = useSelector((state) => state.Auth);
  const addressState = useSelector((state) => state.AddressSlice);
  const [isHovered, setIsHovered] = useState();

  const deletedAddressHandler = (addressId) => {
    if (authState.isLoggedIn && authState.token) {
      dispatch(AddressAction.addressLoading(true));
      const token = localStorage.getItem("token");
      fetch(
        `${process.env.REACT_APP_SERVER_DOMAIN}/address/deleteAddress/${addressId}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + token,
          },
        }
      )
        .then(async (response) => {
          if (!response.ok) {
            const resData = await response.json();
            throw resData;
          }

          return response.json();
        })
        .then((resData) => {
          console.log(resData);
          dispatch(AddressAction.setAddress(resData));
          toastifySuccess(
            "Address removed! The location has been deleted from your delivery options.",
            "🗑️"
          );
          dispatch(AddressAction.addressLoading(false));
        })
        .catch((err) => {
          console.log(err);
          dispatch(AddressAction.addressLoading(false));
        });
    } else {
      history.push("/login");
    }
  };

  const editAddressHandler = (addressId) => {
    if (authState.isLoggedIn && authState.token) {
      history.push(`/editAddress/:${addressId}`);
    } else {
      history.push("/login");
    }
  };

  const deliveryAddressHandler = (addressId) => {
    if (authState.isLoggedIn && authState.token) {
      dispatch(
        AddressAction.setDeliveryAddress({ deliveryAddress: addressId })
      );
      history.push(`/checkout/:${addressId}`);
    }
  };

  return (
    <Card className={styles.addressCard}>
      <h3 className={styles.h3}>
        {`${props.address.firstName} ${props.address.lastName}` ||
          "Akash Verma"}
      </h3>
      <div className={styles.addressCard_address}>
        <MapPin className={styles.icon}></MapPin>
        <p className={styles.p}>
          {`${props.address.address} ${props.address.city} ${props.address.state}-${props.address.zipCode} ` ||
            "dd dfd f fdsdf New Delhi Karnataka"}
        </p>
      </div>
      <div className={styles.addressCard_address}>
        <Phone className={styles.icon} />
        <p className={styles.p}>{props.address?.phoneNumber || "11111111"}</p>
      </div>
      {!addressState.addressLoading ? (
        <Button
          className={styles.btn}
          onClick={() => {
            deliveryAddressHandler(props.addressId);
            setIsHovered(true);
          }}
        >
          Delivery Here
        </Button>
      ) : (
        <Button
          className={styles.btn}
          onMouseEnter={() => {
            setIsHovered(true);
          }}
          onMouseLeave={() => setIsHovered(false)}
        >
          <l-dot-wave
            size="30"
            speed="2.5"
            color={isHovered ? "#e14d2a" : "white"}
          ></l-dot-wave>
        </Button>
      )}
      <div className={styles.icon_container}>
        {!addressState.addressLoading ? (
          <>
            <Trash2
              className={styles.icon_container_icon}
              onClick={() => {
                deletedAddressHandler(props.addressId);
              }}
            />
            <FileEdit
              className={styles.icon_container_icon}
              onClick={() => {
                editAddressHandler(props.addressId);
              }}
            />
          </>
        ) : (
          <l-bouncy
            size="55"
            speed="1.75"
            color="#e14d2a"
            style={{ marginRight: "2px" }}
          ></l-bouncy>
        )}
      </div>
    </Card>
  );
};

export default AddressCard;

import { useEffect } from "react";
import HomeCarousel from "../../FeatureComponents/HomeCarousel/HomeCarousel";
import SectionCarousel from "../../FeatureComponents/SectionCarousel/SectionCarousel";

const HomePage = () => {
  function scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }

  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <div>
      <HomeCarousel />

      <section>
        <SectionCarousel sectionName="Best Selling" />
        <SectionCarousel sectionName="New Arrival" />
      </section>
    </div>
  );
};

export default HomePage;

import { Link, useLocation } from "react-router-dom/cjs/react-router-dom";
import styles from "./_footer.module.scss";
import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ProductAction } from "../../../Store/product-slice";
import { toastifyError } from "../../../Helpers/notificationToastify";
import { Facebook, Youtube, Instagram } from "lucide-react";
import { backDropActions } from "../../../Store/backdrop-slice";
import { FilterAction } from "../../../Store/filter-slice";

const Footer = () => {
  const footerRef = useRef(null);
  const observer = useRef(null);

  const productState = useSelector((state) => state.ProductSlice);
  const dispatch = useDispatch();
  const location = useLocation();

  const currentPath = location.pathname;

  useEffect(() => {
    const currentFooterRef = footerRef.current;

    if (!currentFooterRef || !(currentFooterRef instanceof Element)) {
      return;
    }

    observer.current = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          try {
            if (
              entry.isIntersecting &&
              !productState.isProductFetch &&
              productState.data.length < productState.totalProducts &&
              currentPath === "/products"
            ) {
              console.log("footer into view: ", currentPath);
              dispatch(
                ProductAction.setCurrentPage({
                  currentPage: productState.currentPage + 1,
                })
              );
            }
          } catch (error) {
            console.log(error);
            toastifyError(error.message || "Please try after some time.", "🚫");
          }
        });
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0.1,
      }
    );

    observer.current.observe(currentFooterRef);

    return () => {
      if (currentFooterRef && observer.current) {
        observer.current.unobserve(currentFooterRef);
      }
    };
  }, [
    dispatch,
    productState.currentPage,
    productState.data.length,
    productState.totalProducts,
    productState.isProductFetch,
    currentPath,
  ]);

  return (
    <footer className={styles.footer} ref={footerRef}>
      <div className={styles.footer_container}>
        <div className={styles.footer_container_content}>
          <div className={styles.footer_container_content_listContent}>
            <h1>Mahadev Flutes</h1>
            <div className={styles.socialMedia}>
              <a
                href="https://www.facebook.com/mahadav.flutes?mibextid=hIlR13"
                target="_blank"
                rel="noopener noreferrer"
                className={styles.socialMedia_fb}
              >
                <Facebook />
              </a>
              <a
                href="https://youtube.com/@MahadevFlute55522?si=VG_QVPchKgvKT8ap"
                target="_blank"
                rel="noopener noreferrer"
                className={styles.socialMedia_yt}
              >
                <Youtube />
              </a>
              <a
                href="https://www.instagram.com/mahadevflute?igsh=MzNlNGNkZWQ4Mg=="
                target="_blank"
                rel="noopener noreferrer"
                className={styles.socialMedia_In}
              >
                <Instagram />
              </a>
            </div>
          </div>
          <div className={styles.footer_container_content_listContent}>
            <h2>Shop</h2>
            <ul className={styles.footer_container_content_listContent_list}>
              <li
                className={
                  styles.footer_container_content_listContent_list_listItem
                }
              >
                <Link
                  to="/products"
                  onClick={() => {
                    dispatch(ProductAction.setCurrentPage({ currentPage: 1 }));
                    dispatch(backDropActions.mobileNavHandler(false));
                    dispatch(FilterAction.clearFilter());
                    dispatch(
                      FilterAction.setFilter({ materialType: "fry Bamboo" })
                    );
                  }}
                >
                  FryBamboo Flutes
                </Link>
              </li>
              <li
                className={
                  styles.footer_container_content_listContent_list_listItem
                }
              >
                <Link
                  to="/products"
                  onClick={() => {
                    dispatch(ProductAction.setCurrentPage({ currentPage: 1 }));
                    dispatch(backDropActions.mobileNavHandler(false));
                    dispatch(FilterAction.clearFilter());
                    dispatch(
                      FilterAction.setFilter({ materialType: "acrylic" })
                    );
                  }}
                >
                  Acrylic Flutes
                </Link>
              </li>
            </ul>
          </div>
          <div className={styles.footer_container_content_listContent}>
            <h2>Help</h2>
            <ul className={styles.footer_container_content_listContent_list}>
              <li
                className={
                  styles.footer_container_content_listContent_list_listItem
                }
              >
                <Link to="/PaymentQuery">Payment</Link>
              </li>
              <li
                className={
                  styles.footer_container_content_listContent_list_listItem
                }
              >
                <Link to="/shippingQuery">Shipping</Link>
              </li>
              <li
                className={
                  styles.footer_container_content_listContent_list_listItem
                }
              >
                <Link to="/shippingQuery">Order</Link>
              </li>
            </ul>
          </div>
          <div className={styles.footer_container_content_listContent}>
            <h2>Contact</h2>
            <a href="tel:+8295348750">
              <p>+91 8295348750</p>
            </a>
            <a href="tel:+917011352426">
              <p>+91 7011352426</p>
            </a>
            <a href="mailto:Souravverma55522@gmail.com?subject=Subject%20Here&body=Hi%20Sourav,">
              <p>Souravverma55522@gmail.com</p>
            </a>
          </div>
        </div>
        <div className={styles.footer_container_rights}>
          <h1>@2023 All right reserved. Mahadev Flutes</h1>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
